<template>
  <div>
  <el-dialog :title="goodTitle" :visible.sync="classflag" :before-close="handleClose" width="880px">

      <el-form ref="formAdd" :model="formEmp" label-position="right" label-width="160px">
         <el-form-item label="选择需要打印的SKU：">
          <el-checkbox-group v-model="formEmp.checkList">
            <el-checkbox v-for="(items, indexs) in rowdata.skus" :key="indexs" :label="items.skuName"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
         <el-form-item label="目标打印机：">
           <el-select
                v-model="formEmp.employeeId"
                placeholder="请选择目标打印机"
                filterable
                clearable
                style="width: 220px; margin-right: 10px;"
                @change="clickPoint"
              >
                <el-option
                  v-for="item in employeeData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.status!=1"
                >
                </el-option>
              </el-select>
              <el-button  type="primary" plain @click="gotoDevice" size="small">去添加新的打印设备</el-button>
        </el-form-item>
        <el-form-item label="选择打印模板：">
          
          <el-radio-group v-model="formEmp.templateid">
            <div class="radiobox"  v-for="item in tableData">
            <el-radio :label="item.id">
             <img :src="item.sample" class="imgsize"/>
              <p>{{item.name}}</p>
              <p>尺寸：{{item.paperWidth}} × {{item.paperHeight}}</p>
            </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
        <div class="midCss">
          <el-button @click="handleClose" size="medium">取 消</el-button>
          <el-button type="primary" :loading="logining" @click="addEmployees" size="medium">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getDeviceList, getPointTemplates, getBatchPrintSku } from "@/api/device";
export default {
  name: 'deviceLayer',
  props: ['isShow', 'rowdata'],
  data () {
    return {
      formEmp: {
        employeeId: '',
        templateid: '',
        checkList: []
      },
      tableData: [],
      employeeData: [],
      multipleSelection: [],
      rowmsg: {
        name: '',
        description: ''
      },
      logining: false,
      classflag: false,
      goodTitle: '打印商品标签'
    }
  },
  watch: {
    isShow: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal)
        this.classflag = newVal
        this.getDevices()
      }
    },
    rowdata: {
      deep: true,
      handler (newVals, oldVals) {
        console.log(newVals)
        this.goodTitle = newVals.name
      }
    }
  },
  methods: {
    handleClose(){
      this.classflag = false
     
      this.$emit('cancalfunc', false)
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    getDevices(){
      var that = this
      getDeviceList({}).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.employeeData = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },
    gotoDevice(){
       var that = this
      that.$router.push({
        path: "/device"
      })
    },
    clickPoint(e){
      console.log(e)
      var modelid =''
      this.employeeData.map((item) => {
        if(item.id==e){
         modelid = item.model
        }
      })
      this.getTemList(modelid)
    },
    getTemList(n){
      var that = this
      var objs = {
        "model": n,
	      "scene": "GOODS"
      }
      getPointTemplates(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },

    addEmployees(){
      var that = this
      console.log(that.formEmp.checkList)
      var skuid = []
      that.rowdata.skus.map((item) =>{
        that.formEmp.checkList.map((its) =>{
          if(item.skuName == its){
            skuid.push(item.id)
          }
        })
        
      })
      const param = {
        "deviceId": that.formEmp.employeeId,
        "skuIds": skuid,
        "templateId": that.formEmp.templateid
      }
      getBatchPrintSku(param).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.$message({
              message: '设置打印成功',
              type: 'success'
            });
          that.classflag = false
          that.$emit('cancalfunc', false)
        }else{
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.midCss {
  text-align: right;
  display: block;
  padding-top: 20px;
}
.msgcss {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  line-height:44px;
  margin-bottom: 10px;
}
.msgcss p{
  line-height:22px;
  font-size: 12px;
}
.radiobox{
  width: 160px;
  display: block;
  float: left;
  margin: 5px;
  text-align: center;
  border: 1px solid #eee;
  padding: 5px;
  background-color: #f3f3f3;
}
.imgsize{
  width: 120px;
  height: 120px;display: block;
  background-color: #f3f3f3;
  margin: 10px auto;
}
.radiobox p{
  display: block;
  line-height: 24px;
  font-size: 13px;
}
</style>
