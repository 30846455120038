<template>
  <div>
  <el-dialog :title="goodTitle" :visible.sync="classflag" :before-close="handleClose" width="880px">
       <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          prop="opTime"
          label="操作时间"
          width="100"
          >
        </el-table-column>
         <el-table-column
          prop="action"
          label="操作事件"
          width="100">
        </el-table-column>      
        <el-table-column
          prop="account"
          label="操作人"
          width="100"
        >
        </el-table-column>
         <el-table-column
          prop="message"
          label="日志内容">
        </el-table-column>
      </el-table>
       <div class="paginations">
          <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getlogs } from "@/api/good";
export default {
  name: 'deviceLayer',
  props: ['isShow', 'rowdata'],
  data () {
    return {
      total: 0,
      page: 1,
      pageSize: 20,
      classflag: false,
      tableData: [],
      goodTitle: '打印商品标签'
    }
  },
  watch: {
    isShow: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal)
        this.classflag = newVal
      }
    },
    rowdata: {
      deep: true,
      handler (newVals, oldVals) {
        console.log(newVals)
        this.goodTitle = '【'+newVals.name + '】的操作日志'
        this.getTopSearch()
      }
    }
  },
  methods: {
    handleClose(){
      this.classflag = false
      this.$emit('cancalfunc', false)
    },
    getTopSearch(e){
      var that = this
      var datas = {}
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      datas.id = that.rowdata.id
      getlogs(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data.rows
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  }  
}
</script>

<style scoped>
.midCss {
  text-align: right;
  display: block;
  padding-top: 20px;
}
.msgcss {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  line-height:44px;
  margin-bottom: 10px;
}
.msgcss p{
  line-height:22px;
  font-size: 12px;
}
.radiobox{
  width: 160px;
  display: block;
  float: left;
  margin: 5px;
  text-align: center;
  border: 1px solid #eee;
  padding: 5px;
  background-color: #f3f3f3;
}
.imgsize{
  width: 120px;
  height: 120px;display: block;
  background-color: #f3f3f3;
  margin: 10px auto;
}
.radiobox p{
  display: block;
  line-height: 24px;
  font-size: 13px;
}
</style>
