<template>
  <div>
    <el-dialog :title="title" :visible.sync="flag" 
    :before-close="handleClose"
    width="980px">
      <el-table
        :data="tableData"
        border
        :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
        stripe
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          header-align="center"
          align="center"
          width="35"
        >
        </el-table-column>
        <template v-for="(item, index) in rArrs">
          <el-table-column
            v-if="
              item.role == undefined || item.role == '' || item.role == role
            "
            :prop="item.prop"
            header-align="center"
            align="center"
            :label="item.label"
            :key="index"
            :width="item.width"
          >
            <template slot-scope="scope">
              <span v-if="item.prop === 'image'">
                <el-popover placement="right" trigger="hover">
                  <img
                    :src="scope.row[item.prop].length>0 ? scope.row[item.prop] : backImg"
                    style="width: 200px; height: 200px"
                  />
                  <img
                    slot="reference"
                    :src="scope.row[item.prop].length>0 ? scope.row[item.prop] : backImg"
                    class="sku_image"
                  />
                </el-popover>
              </span>
              <span v-else-if="item.prop === 'skuCode'">
                <el-input
                  placeholder="规格编码不能为空"
                  v-model="scope.row[item.prop]"
                ></el-input>
              </span>
              <span v-else-if="item.prop === 'barCode'">
                <el-input
                  placeholder="输入国标码"
                  v-model="scope.row[item.prop]"
                ></el-input>
              </span>
              <span
                v-else-if="
                  item.prop === 'costPrice' ||
                  item.prop === 'salePrice' ||
                  item.prop === 'linePrice'
                "
              >
                <el-input-number
                  placeholder="请输入"
                  style="width: 80px"
                  v-model="scope.row[item.prop]"
                  :precision="2"
                  :controls="false"
                  @blur="
                    tableMoney(scope.row[item.prop], item.prop, scope.$index)
                  "
                ></el-input-number>
              </span>
              <span v-else-if="item.prop === 'weight'">
                <el-input-number
                  style="width: 82px"
                  placeholder="输入重量"
                  :controls="false"
                  v-model="scope.row[item.prop]"
                  :precision=3
                ></el-input-number>
              </span>
              <span v-else-if="item.prop == 'image' && scope.row[item.prop]">
                <img :src="scope.row[item.prop]" class="sizeimgss" />
              </span>
              <span v-else>
                {{ scope.row[item.prop] }}
              </span>
            </template>
          </el-table-column>
        </template>
      </el-table>

      <div class="dialog-footer" slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveIt" :loading="loading"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { editSku } from "@/api/good";
import backImg from "@/assets/logofang.png";

export default {
  name: "Main",
  data() {
    return {
      backImg: backImg,
      role: null,
      spu: null,
      title: "",
      flag: false,
      tableData: [],
      loading: false,
      rArrs: [
        {
          prop: "image",
          label: "图片",
          width: 50,
        },
        {
          prop: "skuName",
          label: "规格",
          width: 100,
        },
        {
          prop: "skuCode",
          label: "规格编码",
          width: 170,
        },
        {
          prop: "barCode",
          label: "国标条码",
          width: 150,
        },
        {
          prop: "linePrice",
          label: "划线价(元)",
        },
        {
          prop: "salePrice",
          label: "供货价(元)",
          role: "supplier",
        },
        {
          prop: "salePrice",
          label: "销售价(元)",
          role: "retailer",
        },
        {
          prop: "costPrice",
          label: "成本价(元)",
          role: "supplier",
        },
        {
          prop: "costPrice",
          label: "采购价(元)",
          role: "retailer",
        },
        {
          prop: "weight",
          label: "重量(千克)",
        },
      ],
    };
  },
  methods: {
    show(goods) {
      console.log("show", goods)
      const spu = JSON.parse(JSON.stringify(goods))
      this.spu = spu
      this.title = spu.name
      this.role = spu.role
      this.tableData = spu.skus
      this.flag = true
    },
    handleClose() {
      this.spu = null
      this.title = ""
      this.flag = false
      this.tableData = []
    },
    tableMoney(num, names, n) {
      var that = this;
      num = num || 0;
      var nums = parseFloat(num).toFixed(2)
      that.tableData[n][names] = nums || that.tableData[n][names]
    },
    saveIt() {
      const that = this;
      let items = [];
      this.tableData.forEach((sku) => {
        let item = {
          id: sku.id,
        };
        this.rArrs.forEach((key) => {
          const prop = key.prop;
          item[prop] = sku[prop];
        });
        items.push(item);
      });
      const param = {
        id: this.spu.id,
        skus: items,
        role: this.role,
      };

      this.loading = true;
      editSku(param)
        .then((res) => {
          that.loading = false;
          if (res.code === 200) {
            that.$message.success("修改成功")
            that.spu.skus = res.data
            this.$emit("update", that.spu)
          } else {
            that.$message.error(res.message)
          }
        })
        .catch((err) => {
          that.loading = false
          that.$message.error(err)
        });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.sku_image {
  width: 35px;
  height: 35px;
}
</style>